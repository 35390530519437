/*
 * @Author: hzpanji
 * @Date: 2020-01-11 13:12:15
 * @Last Modified by: hzpanji
 * @Last Modified time: 2020-09-27 15:56:15
 */

import _ from 'lodash'

/**
 * @param {*} json
 * @param {*} key
 */
export default function (json, key) {
  const properties = _.cloneDeep(json[key].properties)

  return load(properties)
}

function load(properties) {
  Object.keys(properties).forEach((key) => {
    const item = properties[key]

    if (item.type === 'FieldsGroup') {
      const children = load(item.children)

      if (item.mixin) {
        Object.assign(properties, children)
      } else {
        properties[key] = _.cloneDeep(children)
      }
    } else {
      properties[key] = item.developValue || item.defaultValue
    }
  })

  return properties
}
