<template>
  <div :id="id" :class="['m-city-carrier', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="m-city-carrier-content">
      <VisualItem v-if="mainTitle && mainTitle.value" :visualKeys="mainTitle.visualKeys" @visual="handleVisual" class="m-main-title">
        <p class="content">
          {{ mainTitle.value }}
        </p>
      </VisualItem>
      <VisualItem v-if="secondTitle && secondTitle.value" :visualKeys="secondTitle.visualKeys" @visual="handleVisual" class="m-second-title">
        <p class="content">
          {{ secondTitle.value }}
        </p>
      </VisualItem>
      <div v-if="isPC" class="u-industry-list f-clearfix">
        <VisualItem
          v-for="(item, index) in list.value.slice(0, 4)"
          :key="`industry-${index}`"
          :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]"
          @visual="handleVisual"
          @mouseenter.native="onHoverTrain(index)"
          :class-name="['m-industry', 'f-clearfix', current === index ? 'f-active' : '']"
        >
          <CMSImageBg
            :img="item.img.value"
            :img2x="item.img_2x.value"
            class-name="u-img"
          >
            <div class="u-content">
              <p v-if="item.firstTitle && item.firstTitle.value" class="u-first-title">
                <VisualItem
                  :visualKeys="item.firstTitle.visualKeys"
                  @visual="handleVisual"
                  theme="light"
                >
                  {{ item.firstTitle.value }}
                </VisualItem>
              </p>
              <p v-if="item.secondTitle && item.secondTitle.value" class="u-second-title">
                <VisualItem
                  :visualKeys="item.secondTitle.visualKeys"
                  @visual="handleVisual"
                  theme="light"
                >
                  {{ item.secondTitle.value }}
                </VisualItem>
              </p>
              <p v-if="item.desc && item.desc.value" class="u-desc">
                <VisualItem
                  :visualKeys="item.desc.visualKeys"
                  @visual="handleVisual"
                  theme="light"
                >
                  <div class="text">
                    {{ item.desc.value }}
                  </div>
                </VisualItem>
              </p>
              <VisualItem :visualKeys="[item.jumpLink.visualKeys, item.jumpLinkAnchor.visualKeys]" @visual="handleVisual" class-name="arrow-btn">
                <CMSLink
                  :link="item.jumpLinkAnchor.value ? item.jumpLink.value + '#' + item.jumpLinkAnchor.value : item.jumpLink.value"
                  class-name="btn"
                >
                  <img src="/FourCityCarrier/pc/jump.svg" alt="">
                </CMSLink>
              </VisualItem>
            </div>
          </CMSImageBg>
        </VisualItem>
      </div>
      <div v-else class="u-industry-list">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in list.value"
              :key="`industry-${index}`"
              class="swiper-slide"
            >
              <CMSImageBg
                :img="item.img_2x_h5.value"
                :img2x="item.img_3x_h5.value"
                class-name="u-img"
              >
                <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual">
                  <div class="u-content">
                    <p v-if="item.firstTitle && item.firstTitle.value" class="u-first-title">
                      <VisualItem
                        :visualKeys="item.firstTitle.visualKeys"
                        @visual="handleVisual"
                        theme="light"
                      >
                        {{ item.firstTitle.value }}
                      </VisualItem>
                    </p>
                    <p v-if="item.secondTitle && item.secondTitle.value" class="u-second-title">
                      <VisualItem
                        :visualKeys="item.secondTitle.visualKeys"
                        @visual="handleVisual"
                        theme="light"
                      >
                        {{ item.secondTitle.value }}
                      </VisualItem>
                    </p>
                    <p v-if="item.desc && item.desc.value" class="u-desc">
                      <VisualItem
                        :visualKeys="item.desc.visualKeys"
                        @visual="handleVisual"
                        theme="light"
                      >
                        <div class="text">
                          {{ item.desc.value }}
                        </div>
                      </VisualItem>
                    </p>
                  </div>
                </VisualItem>
              </CMSImageBg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { Swiper as SwiperClass, Navigation, Pagination } from 'swiper'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSLink from '~/components/common/Link'
import CMSImageBg from '~/components/common/ImageBg'
import VisualItem from '~/components/common/VisualItem'
import 'swiper/swiper-bundle.css'

const componentKey = 'FourCityCarrier'

export default {
  components: {
    CMSImageBg,
    VisualItem,
    CMSLink
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    cmsVisual: {
      type: Boolean,
      default: false
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(config, componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data,
      current: 0,
      top: 0
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    SwiperClass.use([Navigation, Pagination])
    this.swiper = new SwiperClass('.swiper-container', {
      slidesPerView: 'auto',
      autoplay: true,
      observer: true,
      observeParents: true
    })
  },
  methods: {
    // changeLine(msg) {
    //   console.log(msg)
    //   return msg.replace(/<\n>/g, '<br/>')
    // },
    onHoverTrain(index) {
      this.current = index
    },
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-city-carrier {
  &.z-web {
    padding: 90px 0 45px;
    width: 100%;
    background: #fff;

    .m-city-carrier-content {
      width: 1200px;
      margin: 0 auto;

      .u-industry-list {
        height: 470px;
        margin-top: 60px;

        .m-industry {
          width: 300px;
          height: 450px;
          position: relative;
          top: 0;
          float: left;
          border-right: 1px solid #eaeef1;
          transition: all 0.5s;

          .arrow-btn {
            position: absolute;
            bottom: 60px;
            left: 0;
            opacity: 0;
            transition: all 0.5s;
          }

          &:last-child {
            border-right: none;
          }

          .u-img {
            height: 100%;
            padding: 160px 40px 0;

            .light {
              color: $mainTitleColor;
            }
          }

          .u-first-title {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 10px;
          }

          .u-second-title {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 34px;
          }

          .u-desc {
            font-size: 14px;
            line-height: 22px;
            display: none;
            margin-bottom: 37px;

            .text {
              @mixin ellipsis-line 7;
            }
          }
        }

        .f-active {
          height: 470px;
          border-radius: 4px;
          position: relative;
          top: -10px;
          background: linear-gradient(147.52deg, #0056fe 10.6%, #448fff 95.38%);
          filter: drop-shadow(0 16px 40px rgba(47, 56, 111, 0.25));
          z-index: 9;

          .u-img {
            background-image: none !important;
            padding: 90px 40px 0;

            .light {
              color: #fff;
            }

            .u-desc {
              font-size: 14px;
              line-height: 22px;
              display: block;

              .text {
                @mixin ellipsis-line 7;
              }
            }
          }

          .arrow-btn {
            left: 20px;
            opacity: 1;
            bottom: 60px;
          }
        }
      }
    }
  }

  &.z-phone {
    padding: pxtorem(52) 0 0 pxtorem(60);

    /* 为了下面轮播图右边的显示 这里要单独对主副标题做右内边距处理 */
    .m-main-title,
    .m-second-title {
      padding-right: pxtorem(60);
    }

    .m-second-title {
      font-size: pxtorem(28);
      line-height: pxtorem(48);
      color: #6f7993;
    }

    .swiper-container {
      width: 100%;
      margin-top: pxtorem(60);

      .swiper-wrapper {
        .swiper-slide {
          width: pxtorem(560);
          height: pxtorem(880);
          margin-right: pxtorem(40);
          position: relative;
          overflow: hidden;

          .u-img {
            height: 100%;
            background-size: cover;
            padding: pxtorem(252) pxtorem(60) pxtorem(132);

            .u-first-title {
              color: $mainTitleColor;
              font-size: pxtorem(48);
              line-height: pxtorem(68);
              margin-bottom: pxtorem(20);

              .light {
                @mixin ellipsis;
              }
            }

            .u-second-title {
              font-size: pxtorem(28);
              line-height: pxtorem(40);
              margin-bottom: pxtorem(40);
              color: $mainTitleColor;

              .light {
                @mixin ellipsis-line 2;
              }
            }

            .u-desc {
              font-size: pxtorem(24);
              line-height: pxtorem(48);
              color: #6f7993;
              @mixin ellipsis-line 6;
            }
          }
        }
      }
    }
  }
}

@media only screen and $webmin {
  .z-web .m-city-carrier-content {
    width: 1200px !important;

    .u-industry-list .m-industry {
      width: 300px !important;
    }
  }
}
</style>
