<template>
  <div @click.capture="handleClick" :class="[cmsVisual ? 'm-visual-item' : '', theme ? theme : '', className]">
    <slot />
    <!-- <div v-if="cmsVisual" class="u-border u-border-top" />
    <div v-if="cmsVisual" class="u-border u-border-right" />
    <div v-if="cmsVisual" class="u-border u-border-bottom" />
    <div v-if="cmsVisual" class="u-border u-border-left" /> -->
    <div :class="[isActive && showBorder ? 'is-active' : '']" class="u-border-hover" />
    <a
      v-if="tip && cmsVisual"
      @click="doVisual"
      class="u-tip"
    >
      {{ tip }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    visualKeys: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    },
    developValue: {
      type: Object,
      default() {
        return {}
      }
    },
    className: {
      type: [String, Array],
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    },
    showBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cmsVisual() {
      return this.$store.state.cmsVisual
    },
    isActive() {
      let isActive = false
      if (Array.isArray(this.$store.state.currentVisualKeys) && Array.isArray(this.visualKeys)) {
        const currentVisualKeys = this.$store.state.currentVisualKeys.join('-')
        const visualKeys = this.visualKeys.join('-')
        if (currentVisualKeys === visualKeys) {
          isActive = true
        } else {
          isActive = false
        }
        return isActive
      }
      if (this.$store.state.currentVisualKeys === this.visualKeys) {
        isActive = true
      } else {
        isActive = false
      }
      return isActive
    }
  },
  methods: {
    handleClick(e) {
      if (this.cmsVisual) {
        e.preventDefault()

        if (!this.tip) {
          this.doVisual()
        }
      }
    },
    doVisual() {
      this.$emit('visual', {
        keys: this.visualKeys,
        type: this.type,
        developValue: this.developValue
      })
    }
  }
}
</script>

<style lang="postcss">
.m-visual-item {
  cursor: pointer;
  position: relative;
  display: block;

  .u-tip {
    display: none;
  }

  &:hover {
    > .u-border {
      position: absolute;
      border-color: rgba(0, 0, 0, 0.3);

      &.u-border-top {
        top: 0;
        left: 0;
        width: 100%;
        border-top: 1px dashed;
      }

      &.u-border-right {
        top: 0;
        right: 0;
        height: 100%;
        border-right: 1px dashed;
      }

      &.u-border-bottom {
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px dashed;
      }

      &.u-border-left {
        left: 0;
        top: 0;
        height: 100%;
        border-left: 1px dashed;
      }
    }

    .u-tip {
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -50px;
      min-width: 100px;
      line-height: 70px;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 998;
      top: 0;
      text-align: center;
    }
  }

  &.light:hover {
    > .u-border {
      border-color: #6ccfff;
    }
  }

  .u-border-hover {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    transition: border 0.2s ease;

    &.is-active {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border: 2px dashed #6ccfff !important;
    }
  }

  &:hover {
    > .u-border-hover {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border: 1px dashed #6ccfff;
    }
  }
}
</style>
