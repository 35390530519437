<template>
  <a :class="['link', className]" :[hrefKey]="href" :target="target">
    <slot />
  </a>
  <!-- <n-link :class="['link', className]" :to="href" :target="target" no-prefetch>
    <slot />
  </n-link> -->
</template>

<script>
export default {
  props: {
    link: {
      type: [String, Object],
      default: ''
    },
    className: {
      type: [String, Array],
      default: ''
    }
  },
  data() {
    try {
      let href = this.link
      let isTarget = false
      const str = 'target:'

      if (href.indexOf(str) === 0) {
        isTarget = true
        href = href.replace(str, '')
      }

      return {
        href: href,
        hrefKey: href ? 'href' : '',
        target: isTarget ? '_blank' : '_self'
      }
    } catch (error) {
      return {
        href: '',
        hrefKey: '',
        target: '_self'
      }
    }
  }
}
</script>
